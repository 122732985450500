import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Header } from 'components/theme'
import { Container, Button } from 'components/common'
// import dev from 'assets/illustrations/dev.svg'
import dev from 'assets/illustrations/motion.gif'
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles'
import {
	videoIllustration1
  } from 'data/config'
export const Intro = () => (
	<Wrapper id="describe">
		<Header />
		<IntroWrapper as={Container}>
			<Details>
				<h1>Creates values in Web and Mobile Development.</h1>
				<h5>Having an idea and make it works for partners is sparkling experiences</h5>
				<Button as={AnchorLink} href="#contact">
					Get in touch
				</Button>
			</Details>
			<Thumbnail>
				{/* <video id="background-video" loop autoPlay muted>
					<source src={videoIllustration1} type="video/mp4" />
					<source src={videoIllustration1} type="video/ogg" />
					Unfortunately, Your browser does not support the video tag.
				</video> */}
				<img src={dev}></img>
			</Thumbnail>
		</IntroWrapper>
	</Wrapper>
)
