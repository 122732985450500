import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Card } from 'components/common'
import {
  edges
} from 'data/config'
import { ProjectLists } from 'components/landing/Projects/ProjectLists'
import { Wrapper, Grid, Item, Content } from './styles'
export const Projects = () => {
  return (
    <Wrapper as={Container} id="projects">
      <h2>Projects:</h2>
      <ProjectLists/>
    </Wrapper>
  )
}
