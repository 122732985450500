import React, {useState, useEffect } from 'react'
import { Card, Button } from 'components/common'
import {
  edges
} from 'data/config'
import { Grid, Item, Content, ShowClickButton, Pointer, RectangleImage, Description } from './styles'
import Carousel, { Modal, ModalGateway } from 'react-images';
export const ProjectLists = () => {
    const [state, setState] = useState({
        shownAll: false,
        modalIsOpen: false,
        imageShow: null
    });
    
    useEffect(() => {
        const handleShowClick = () => {
            setShownAll(true);
        }
    })
    const toggleVisibility = () => {
        setState({
            ...state,
            shownAll: true,
        });
    };
    const toggleModal = (boolVal, image) => {
        setState({
            ...state,
            modalIsOpen: boolVal,
            imageShow: (image) ? image : [{ source: 'https://media.publit.io/file/sparksdna.png' }]
        });
    };
    return (
        <div>
            <ModalGateway>
                {state.modalIsOpen ? (
                <Modal onClose={() => toggleModal(false, null)}>
                    <Carousel views={state.imageShow} />
                </Modal>
                ) :  null}
            </ModalGateway>
            <Grid>
                {edges.map((node,idx) =>
                    (!state.shownAll && idx < 6) ?
                        <Item
                            key={node.id}
                            // as="a"
                            // href={node.url}
                            // target="_blank"
                            // rel="noopener noreferrer"
                        >
                            <Pointer>
                                <Card className="pointer">
                                    <Content>
                                        <Item className="title" as="a" href={node.url} target="_blank" rel="noopener noreferrer">{node.name}</Item>
                                        <RectangleImage onClick={() => toggleModal(true, node.imageLarge)}>
                                            <img src={node.image}/>
                                        </RectangleImage>
                                        <Description>
                                            <p>{node.description}</p>
                                        </Description>
                                    </Content>
                                </Card>
                            </Pointer>
                        </Item>
                    : (state.shownAll) ?
                        <Item
                            key={node.id}
                            // as="a"
                            // href={node.url}
                            // target="_blank"
                            // rel="noopener noreferrer"
                        >
                            <Pointer>
                                <Card className="pointer">
                                    <Content>
                                        <Item className="title" as="a" href={node.url} target="_blank" rel="noopener noreferrer">{node.name}</Item>
                                        <RectangleImage onClick={() => toggleModal(true, node.imageLarge)}>
                                            <img src={node.image}/>
                                        </RectangleImage>
                                        <p>{node.description}</p>
                                    </Content>
                                </Card>
                            </Pointer>
                        </Item>
                    :
                    <div></div>
                )}
                {/* {
                    (!state.shownAll) ? 
                    <ShowClickButton>
                        <Button secondary type="button" onClick={toggleVisibility}>
                            Show All
                        </Button>
                    </ShowClickButton>
                    :
                    <ShowClickButton></ShowClickButton>
                } */}
            </Grid>
        </div>
    )
}
